import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SideNavLayout, useScopedSelector } from 'ia-react-core';
import { ThemeContext } from 'styled-components';
import { NotificationsIcon } from 'we-package-react-notifications';
import { AuthenticationContext, ApplicationLayoutProps as ApplicationLayoutPropsCore, ConsultationModeHeader, useFooterLinks } from 'omni-package-react-core';
import Account from '~/components/Account';
import { useSitecoreContentQuery } from 'omni-package-react-cms';
import ApplicationWarnings from '~/components/ApplicationWarnings';
import useSideNavLinks from './hooks/useSideNavLinks';

export interface ApplicationLayoutProps extends ApplicationLayoutPropsCore { }

const ApplicationLayout: FC<ApplicationLayoutProps> = ({ children, ...props }) => {
  const { t } = useTranslation('App');
  const theme = useContext(ThemeContext);
  const links = useSideNavLinks();

  const { authenticationInfo } = useContext(AuthenticationContext);
  const authValue = authenticationInfo.isAuthenticated ? 'authenticated' : 'non-authenticated';
  const mainFooterLinksUrl = useSitecoreContentQuery(`global/footers/footer-react-${authValue}`, true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const footerLinksFromSitecore = useScopedSelector<Array<any>>('/App.MainFooterDynamicLinksLinksResourceData') ?? [];
  const footerLinksFromOmniCore = useFooterLinks() ?? [];

  return (
    <SideNavLayout
      contentBackground={`linear-gradient(214deg, ${theme.colors.blue12},${theme.colors.green12} 79%)`}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      appName={t('mainHeader.ClientSpace').toUpperCase()}
      noLanguageButtonNav
      mainFooterLinksUrl={mainFooterLinksUrl}
      mainFooterLinks={footerLinksFromOmniCore}
      noMainFooterLinksLoader
      maxContentWidth={1136}
      sideNavVisible={authenticationInfo.isAuthenticated}
      languageButtonInlineWithSlogan={(footerLinksFromOmniCore.length + footerLinksFromSitecore.length) === 0}
      navTopBorder="0"
      {...props}
    >
      <SideNavLayout.BeforeHeader>
        <ConsultationModeHeader />
      </SideNavLayout.BeforeHeader>
      <SideNavLayout.SideNav>
        {links}
      </SideNavLayout.SideNav>
      <SideNavLayout.Content>
        <ApplicationWarnings />

        {children || null}
      </SideNavLayout.Content>
      {authenticationInfo.isAuthenticated &&
        <SideNavLayout.HeaderRightContent>
          <NotificationsIcon />
          <Account />
        </SideNavLayout.HeaderRightContent>}
    </SideNavLayout>

  );
};

export default ApplicationLayout;
